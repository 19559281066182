import {
  EnvironmentInjector,
  inject,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { PageStrategy } from 'src/app/page-strategy';

export function loadComponent(
  environmentInjector: EnvironmentInjector,
  vcr: ViewContainerRef | undefined,
): void {
  if (!vcr) {
    throw new Error('View container is undefined');
  }
  environmentInjector.runInContext(() => {
    const pageStrategy = inject(PageStrategy);
    const { component } = pageStrategy;

    if (component) {
      vcr.createComponent(component as Type<unknown>);
    } else {
      throw new Error('Component in PageStrategy is undefined');
    }
  });
}
