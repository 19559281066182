import {
  Directive,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

export class ErrorMessages {
  public static passwordRequired = 'form.validation_messages.password_required';
  public static passwordNotMatch =
    'form.validation_messages.password_not_match';
  public static password = 'form.validation_messages.password';
  public static minlength = 'form.validation_messages.minlength';
  public static email = 'form.validation_messages.email';
  public static emailRequired = 'form.validation_messages.email_required';
}

@Directive({
  selector: '[validationMessage]',
  standalone: true,
  providers: [TranslatePipe],
})
export class ValidationMessageDirective implements OnInit, OnDestroy {
  private control = inject(NgControl, { optional: true, self: true });
  private renderer = inject(Renderer2);
  private translatePipe = inject(TranslatePipe);
  private element: ElementRef<HTMLElement> = inject(ElementRef);
  private unsubscribe: Subject<any> = new Subject<any>();
  private messageEl: ElementRef<HTMLElement> | undefined;

  ngOnInit() {
    this.control?.valueChanges
      ?.pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.drawMessage();
      });

    this.control?.statusChanges
      ?.pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.drawMessage();
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  private drawMessage(element: ElementRef<HTMLElement> = this.element) {
    if (
      this.messageEl &&
      element.nativeElement.contains(this.messageEl as any)
    ) {
      this.renderer.removeChild(element.nativeElement, this.messageEl);
    }
    if (this.control?.dirty && this.control.invalid && this.control.errors) {
      this.messageEl = this.renderer.createElement('span');
      this.renderer.addClass(this.messageEl, 'error-message');
      const messages = Object.keys(this.control.errors).map(
        (errorName: keyof ErrorMessages extends String ? string : any) => {
          return this.translatePipe.transform(
            ErrorMessages[errorName as keyof ErrorMessages],
          );
        },
      );
      const message = this.renderer.createText(messages.join());
      this.renderer.appendChild(this.messageEl, message);
      this.renderer.appendChild(element.nativeElement, this.messageEl);
    }
  }
}
