import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RegExpList } from 'src/app/core/utils/reg-exp';
import { AutofocusDirective } from 'src/app/directives/autofocus.directive';

@Component({
  selector: 'app-text-field',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule, AutofocusDirective],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextFieldComponent),
      multi: true,
    },
  ],
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextFieldComponent implements ControlValueAccessor {
  @ViewChild('input', { read: ElementRef, static: true })
  input: ElementRef<HTMLInputElement> | undefined;

  @Input()
  name: string = '';

  @Input()
  label: string = '';

  @Input()
  inputId: string = '';

  @Input()
  autocomplete: string = 'off';

  @Input()
  autofocus: string | boolean = false;

  @Input()
  type: 'text' | 'password' = 'text';

  @Input()
  placeholder: string = '';

  @Input()
  showPasswordButton: boolean = false;

  RegExpList = RegExpList;
  onChange: any = () => {};
  onTouch: any = () => {};
  val = '';

  set value(val: any) {
    if (val !== undefined && this.val !== val) {
      this.val = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  checkInputValue(event: ClipboardEvent): boolean;
  checkInputValue(event: KeyboardEvent): boolean;
  checkInputValue(event: any): boolean {
    if (this.type === 'password') {
      if (event instanceof ClipboardEvent) {
        const { clipboardData } = event;
        const pastedData = clipboardData?.getData('text/plain') || '';
        return !!pastedData.match(RegExpList.allowedCharacters);
      } else {
        return !!event.key?.match(RegExpList.allowedCharacters);
      }
    } else {
      return true;
    }
  }

  toggleInputType(input: HTMLInputElement): void {
    const { type } = input;
    input.type = type === 'text' ? 'password' : 'text';
    this.type = input.type as typeof this.type;
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}
}
