import { FeatureFlagPreset } from '../../app/core/types/feature-flags.type';

export const environment = {
  production: false,
  appBaseHref: '/',
  apiUrl: 'https://api-dev.super-app.leafio.cloud/api/login',

  invitationApiUrl: 'https://api-dev.super-app.leafio.cloud/api/users/invitation',
  checkInvitationApiUrl: 'https://api-dev.super-app.leafio.cloud/api/users/invitation/check',

  resetPasswordApiUrl: 'https://api-dev.super-app.leafio.cloud/api/users/reset_password',
  checkResetPasswordApiUrl: 'https://api-dev.super-app.leafio.cloud/api/users/reset_password/check',

  featuresPreset: 'super' as FeatureFlagPreset,
};
