import { inject, Injectable } from '@angular/core';
import { FeatureFlagPreset, FeatureFlags } from '../../core/types/feature-flags.type';
import { FEATURE_FLAG_PRESET } from '../../core/token/feture-flags.token';

const DEFAULT_FEATURES: Record<FeatureFlagPreset, FeatureFlags> = {
  assortment: {
    nameForWelcomeText: 'Assortment Performance system',
    websiteTitle: 'LeafioAuthorization',
    validateInvitationToken: false,
    actionAfterRegistration: 'redirect_to_login',
  },
  super: {
    nameForWelcomeText: 'Leafio Super App',
    websiteTitle: 'Authorization',
    logoText: 'Leafio\nSuper App',
    validateInvitationToken: true,
    actionAfterRegistration: 'redirect_to_download_app',
    platformName: 'admin_panel',
    downloadAppLinks: {
      AppStore: {
        qr: 'assets/images/qr_code_super_app_store.png',
        href: 'https://apps.apple.com/us/app/leafio-super-app/id6474915557',
      },
      GooglePlay: {
        qr: 'assets/images/qr_code_super_google_play.png',
        href: 'https://play.google.com/store/apps/details?id=com.leafiomobileapp.production',
      },
    },
  },
};

@Injectable({
  providedIn: 'root'
})
export class FeaturesService {
  protected featuresPreset = inject(FEATURE_FLAG_PRESET);

  protected features = DEFAULT_FEATURES[this.featuresPreset];

  get<T extends keyof FeatureFlags>(name: T): FeatureFlags[T] {
    return this.features[name];
  }
}
