import { User } from '../interfaces/user.interface';

export class UserDto {
  user: {
    email: string;
    password: string;
    remember_me: boolean;
  };
  constructor(user: User) {
    this.user = {
      email: user.email,
      password: user.password,
      remember_me: user.rememberMe,
    };
  }

  static create(user: User): UserDto {
    return new UserDto(user);
  }
}
