import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { UserDto } from 'src/app/core/model/user.model';
import { ReturnUrlService } from '../return-url/return-url.service';
import { environment } from 'src/environments/environment';
import { UserPasswords } from 'src/app/core/interfaces/user.interface';
import {
  COMPANY_ID,
  AUTHORIZATION_HEADER,
  INV_TOKEN,
  TOKEN,
  BEARER_TOKEN,
  RESET_PASS_TOKEN,
  DOWNLOAD,
} from 'src/app/core/constants/auth.constants';
import { getSearchParam } from '../../core/utils/get-search-param';
import { FeaturesService } from '../features/features.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private http = inject(HttpClient);
  private returnUrlService = inject(ReturnUrlService);
  private featuresService = inject(FeaturesService);
  private defaultView = inject(DOCUMENT).defaultView;

  #jwtToken: string | undefined | null;

  login(user: UserDto): Observable<any> {
    const body: Record<string, any> = {
      ...user,
      company_id: getSearchParam(COMPANY_ID),
    };

    let platformName = this.featuresService.get('platformName');
    if (platformName) {
      body['platform'] = platformName;
    }

    return this.http
      .post(environment.apiUrl, body, { observe: 'response' })
      .pipe(
        tap((response) => {
          this.#jwtToken = response.headers.get(AUTHORIZATION_HEADER);
          if (this.#jwtToken) {
            this.redirectUserByReturnUrl();
          }
        }),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      );
  }

  register(form: UserPasswords) {
    const companyName = getSearchParam(COMPANY_ID);
    const invitationToken = getSearchParam(INV_TOKEN);

    return this.http
      .put(environment.invitationApiUrl, {
        password: form.password,
        password_confirmation: form.confirmPassword,
        invitation_token: invitationToken,
        company_id: companyName,
      })
      .pipe(
        tap(() => this.executePostRegistrationAction()),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      );
  }

  resetPassword(form: UserPasswords) {
    const companyName = getSearchParam(COMPANY_ID);
    const resetToken = getSearchParam(RESET_PASS_TOKEN);

    return this.http
      .put(environment.resetPasswordApiUrl, {
        password: form.password,
        password_confirmation: form.confirmPassword,
        reset_password_token: resetToken,
        company_id: companyName,
      })
      .pipe(
        tap(() => this.executePostRegistrationAction()),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      );
  }

  isInvitationTokenValid(): Observable<boolean> {
    if (!this.featuresService.get('validateInvitationToken')) {
      return of(true);
    }

    const token = getSearchParam(INV_TOKEN);
    return this.http
      .get<boolean>(environment.checkInvitationApiUrl, {
        params: {
          invitation_token: token,
        }
      })
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  isResetPasswordTokenValid(): Observable<boolean> {
    const token = getSearchParam(RESET_PASS_TOKEN);
    return this.http
      .get<boolean>(environment.checkResetPasswordApiUrl, {
        params: {
          reset_password_token: token,
        }
      })
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  setTokenFromLocalStorage() {
    this.#jwtToken = this.getJwtTokenFromLocalStorage();
  }

  executePostRegistrationAction(): void {
    const baseHref = environment.appBaseHref;
    switch (this.featuresService.get('actionAfterRegistration')) {
      case 'redirect_to_login':
        this.defaultView?.location.replace(
          `${baseHref}?${COMPANY_ID}=${getSearchParam(COMPANY_ID)}`
        );
        return;
      case 'redirect_to_download_app':
        this.defaultView?.location.replace(
          `${baseHref}?${DOWNLOAD}`
        );
        return;
    }
  }

  redirectUserByReturnUrl(): void {
    if (this.returnUrlService.returnUrl && this.defaultView) {
      const url = new URL(this.returnUrlService.returnUrl);
      url.searchParams.set(TOKEN, this.#jwtToken as string);
      url.searchParams.set(COMPANY_ID, getSearchParam(COMPANY_ID));
      this.defaultView.location.replace(url);
    }
  }

  isEqualCompanies() {
    const savedCompany = localStorage.getItem(COMPANY_ID);
    const comparedCompany = getSearchParam(COMPANY_ID);

    return savedCompany && comparedCompany && savedCompany === comparedCompany;
  }

  private getJwtTokenFromLocalStorage() {
    return localStorage.getItem(BEARER_TOKEN);
  }
}
