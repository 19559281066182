import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthComponent } from 'src/app/core/interfaces/auth-component.interface';
import { ErrorComponent } from '../../components/error/error.component';

@Component({
  selector: 'app-invitation-expired-state',
  standalone: true,
  imports: [
    CommonModule,
    ErrorComponent,
  ],
  templateUrl: './invitation-expired-state.component.html',
  styleUrls: ['./invitation-expired-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvitationExpiredStateComponent implements AuthComponent {}
