import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
  name: 'errors',
  standalone: true,
  pure: false,
})
export class ErrorsPipe implements PipeTransform {
  transform(form: FormGroup, errorName: string): boolean {
    return form.hasError(errorName);
  }
}
