import { InjectionToken } from '@angular/core';
import { FeatureFlagPreset } from '../types/feature-flags.type';
import { environment } from '../../../environments/environment';

export const FEATURE_FLAG_PRESET = new InjectionToken<FeatureFlagPreset>(
  'feature flags preset',
  {
    providedIn: 'root',
    factory: () => environment.featuresPreset,
  }
);
