import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FeaturesService } from '../../services/features/features.service';

@Component({
  selector: 'app-header-caption',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './header-caption.component.html',
  styleUrls: ['./header-caption.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderCaptionComponent {
  featuresService = inject(FeaturesService);

  @Input()
  text: string = '';

  translateParams = {
    appName: this.featuresService.get('nameForWelcomeText'),
  };
}
