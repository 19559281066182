import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeaderCaptionComponent } from 'src/app/components/header-caption/header-caption.component';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { LoginFormComponent } from 'src/app/components/login-form/login-form.component';
import { AuthComponent } from 'src/app/core/interfaces/auth-component.interface';

@Component({
  selector: 'app-authorization',
  standalone: true,
  imports: [
    CommonModule,
    HeaderCaptionComponent,
    HeaderComponent,
    LoginFormComponent,
  ],
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorizationComponent implements AuthComponent {}
