import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  Component,
  EnvironmentInjector,
  inject,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { RememberPasswordComponent } from './components/remember-password/remember-password.component';
import { FORGOT_PASSWORD_VISIBILITY } from './core/token/forgot-password-modal-visibility.token';
import { loadComponent } from './core/utils/load-component';
import { LoginService } from './services/login/login.service';
import { BEARER_TOKEN } from './core/constants/auth.constants';
import { FeaturesService } from './services/features/features.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    RememberPasswordComponent,
    NgOptimizedImage,
  ],
})
export class AppComponent implements OnInit {
  title = 'leafio-authorization';

  forgotPassVisibility = inject(FORGOT_PASSWORD_VISIBILITY);
  featuresService = inject(FeaturesService);
  titleService = inject(Title);

  logoText = this.featuresService.get('logoText');

  @ViewChild('formContainer', { read: ViewContainerRef, static: true })
  formContainer: ViewContainerRef | undefined;

  private environmentInjector = inject(EnvironmentInjector);

  private loginService = inject(LoginService);

  ngOnInit(): void {
    if (this.canLogin()) {
      this.loginService.setTokenFromLocalStorage();
      this.loginService.redirectUserByReturnUrl();
    } else {
      loadComponent(this.environmentInjector, this.formContainer);
    }
    this.titleService.setTitle(this.featuresService.get('websiteTitle'));
  }

  private canLogin() {
    return (
      !!localStorage.getItem(BEARER_TOKEN) &&
      this.loginService.isEqualCompanies()
    );
  }
}
