<form [formGroup]="form"
      #formEl
      (ngSubmit)="login()"
      (change)="formEl.click()">
  <app-text-field [name]="'email'"
                  [inputId]="'user_email'"
                  [autocomplete]="'username'"
                  [autofocus]="true"
                  [type]="'text'"
                  validationMessage
                  formControlName="email"
                  [label]="'form.email'"></app-text-field>
  <app-text-field [name]="'password'"
                  [inputId]="'user_password'"
                  [autocomplete]="'current-password'"
                  [type]="'password'"
                  validationMessage
                  formControlName="password"
                  [label]="'form.password'"
                  [showPasswordButton]="true"></app-text-field>
  <div class="actions">

    <label class="container">{{ 'remember_me' | translate }}
      <input type="checkbox"
             class="checkbox"
             formControlName="rememberMe"
             name="remember" />
      <span class="checkmark"></span>
    </label>
    <button class="link"
            type="button"
            (click)="visibility.next(true)">{{ 'forgot_password' | translate }}</button>
  </div>

  <button type="submit"
          [disabled]="(form.statusChanges | async) === 'INVALID'"
          class="btn">
    {{ 'login' | translate }}
  </button>
</form>


<div class="failed-login">
  {{errorMessage}}
</div>
