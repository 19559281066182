import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthComponent } from '../../core/interfaces/auth-component.interface';
import { HeaderCaptionComponent } from '../../components/header-caption/header-caption.component';
import { HeaderComponent } from '../../components/header/header.component';
import { FeaturesService } from '../../services/features/features.service';
import { DOWNLOAD_APP_BADGES } from '../../core/constants/download-app-badges.constants';

@Component({
  selector: 'app-download-app',
  standalone: true,
  imports: [CommonModule, HeaderCaptionComponent, HeaderComponent],
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadAppComponent implements AuthComponent {
  featuresService = inject(FeaturesService);
  links = this.featuresService.get('downloadAppLinks');
  badges = DOWNLOAD_APP_BADGES;
}
