import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeaderCaptionComponent } from 'src/app/components/header-caption/header-caption.component';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { RegistrationFormComponent } from 'src/app/components/registration-form/registration-form.component';
import { AuthComponent } from 'src/app/core/interfaces/auth-component.interface';
import { LoginService } from '../../services/login/login.service';
import { UserPasswords } from '../../core/interfaces/user.interface';

@Component({
  selector: 'app-registration',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    HeaderCaptionComponent,
    RegistrationFormComponent,
  ],
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationComponent implements AuthComponent {
  submitFn(loginService: LoginService, data: UserPasswords) {
    return loginService.register(data);
  }
}
