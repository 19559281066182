import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthComponent } from '../../core/interfaces/auth-component.interface';
import { HeaderComponent } from '../../components/header/header.component';
import { HeaderCaptionComponent } from '../../components/header-caption/header-caption.component';
import { RegistrationFormComponent } from '../../components/registration-form/registration-form.component';
import { LoginService } from '../../services/login/login.service';
import { UserPasswords } from '../../core/interfaces/user.interface';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    HeaderCaptionComponent,
    RegistrationFormComponent,
  ],
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordComponent implements AuthComponent {
  submitFn(loginService: LoginService, data: UserPasswords) {
    return loginService.resetPassword(data);
  }
}
