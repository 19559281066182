import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FORGOT_PASSWORD_VISIBILITY } from 'src/app/core/token/forgot-password-modal-visibility.token';

@Component({
  selector: 'app-remember-password',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './remember-password.component.html',
  styleUrls: ['./remember-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RememberPasswordComponent {
  visibility = inject(FORGOT_PASSWORD_VISIBILITY);
}
