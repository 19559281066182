<app-header [text]="'header.download_app'"></app-header>
<app-header-caption [text]="'header.download_app_caption'"></app-header-caption>
<div class="links-wrapper" *ngIf="links">
  <div class="link" *ngIf="links.AppStore">
    <div class="qr-code">
      <img class="link-img qr-code-content" [src]="links.AppStore.qr" alt="AppStore QR Code"/>
    </div>
    <a class="store-btn" [href]="links.AppStore.href" target="_blank">
      <img class="link-img store-btn-content store-btn-content_app-store" [src]="badges.AppStore" alt="Download on the AppStore"/>
    </a>
  </div>
  <div class="link link_with-birdy" *ngIf="links.GooglePlay">
    <div class="qr-code">
      <img class="link-img qr-code-content" [src]="links.GooglePlay.qr" alt="GooglePlay QR Code"/>
    </div>
    <a class="store-btn" [href]="links.GooglePlay.href" target="_blank">
      <img class="link-img store-btn-content store-btn-content_google-play" [src]="badges.GooglePlay" alt="Get it on GooglePlay"/>
    </a>
  </div>
</div>
