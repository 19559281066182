import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ReturnUrlService {
  returnUrl: string | undefined | null = undefined;

  private defaultView = inject(DOCUMENT).defaultView;
  constructor() {
    if (this.defaultView) {
      const searchParams = new URLSearchParams(
        this.defaultView.location.search,
      );
      this.returnUrl = searchParams.get('returnUrl') || this.defaultView.location.origin;
    }
  }
}
