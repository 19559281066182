<div class="field-wrapper">
  <label [for]="name">{{ label | translate }}</label>
  <div class="input-wrapper">
    <input [name]="name"
           [id]="inputId"
           [placeholder]="placeholder"
           [type]="type"
           [autocomplete]="autocomplete"
           (keydown)="checkInputValue($any($event))"
           [autofocus]="autofocus"
           (paste)="checkInputValue($any($event))"
           [(ngModel)]="value"
           #input>
    <button *ngIf="showPasswordButton"
            type="button"
            class="password-button"
            [ngClass]="type"
            (click)="toggleInputType(input)"></button>
  </div>
</div>
