import { PageStrategy } from '../../page-strategy';
import { map, Observable, of } from 'rxjs';
import { AuthComponent } from '../interfaces/auth-component.interface';
import { LoginService } from '../../services/login/login.service';
import { DOWNLOAD, INV_TOKEN, RESET_PASS_TOKEN } from '../constants/auth.constants';

export function ResolvePageComponentFactory(
  pageStrategy: PageStrategy,
  loginService: LoginService,
): () => Observable<AuthComponent | undefined> {
  return () => {
    const urlParams = new URLSearchParams(window.location.search);
    let component: AuthComponent | undefined;

    if (urlParams.has(DOWNLOAD)) {
      component = pageStrategy.get('download_app');
      pageStrategy.component = component;
      return of(component);
    }

    if (urlParams.has(RESET_PASS_TOKEN)) {
      return loginService.isResetPasswordTokenValid().pipe(
        map(isValid => {
          component = isValid
            ? pageStrategy.get('reset_password')
            : pageStrategy.get('token_expired');

          pageStrategy.component = component;
          return component;
        })
      )
    }

    if (urlParams.has(INV_TOKEN)) {
      return loginService.isInvitationTokenValid().pipe(
        map(isValid => {
          component = isValid
            ? pageStrategy.get('register')
            : pageStrategy.get('token_expired');

          pageStrategy.component = component;
          return component;
        })
      );
    }

    component = pageStrategy.get('signin');
    pageStrategy.component = component;
    return of(component);
  };
}
