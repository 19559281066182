import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { firstValueFrom, tap } from 'rxjs';
import { User } from 'src/app/core/interfaces/user.interface';
import { UserDto } from 'src/app/core/model/user.model';
import { FORGOT_PASSWORD_VISIBILITY } from 'src/app/core/token/forgot-password-modal-visibility.token';
import { LoginService } from 'src/app/services/login/login.service';
import { ValidationMessageDirective } from 'src/app/validators/validation-message/validation-message.directive';
import {
  EmailValidators,
  PasswordValidators,
} from 'src/app/validators/validator';
import { TextFieldComponent } from '../controls/text-field/text-field.component';

@Component({
  selector: 'app-login-form',
  standalone: true,
  imports: [
    CommonModule,
    TextFieldComponent,
    ReactiveFormsModule,
    TranslateModule,
    ValidationMessageDirective,
  ],
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent {
  visibility = inject(FORGOT_PASSWORD_VISIBILITY);
  loginService = inject(LoginService);
  errorMessage: string = '';

  private cdr = inject(ChangeDetectorRef);

  form = new FormGroup({
    password: new FormControl('', [
      PasswordValidators.passwordRequired,
      Validators.minLength(8),
    ]),
    email: new FormControl('', [
      Validators.email,
      EmailValidators.emailRequired,
    ]),
    rememberMe: new FormControl(false),
  });

  async login(): Promise<void> {
    const user = UserDto.create(this.form.getRawValue() as User);
    await firstValueFrom(
      this.loginService.login(user).pipe(
        tap((msg) => {
          this.errorMessage = msg.error.error || msg.error;
          this.cdr.markForCheck();
        })
      )
    );
  }
}
