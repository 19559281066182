import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[autofocus]',
  standalone: true,
})
export class AutofocusDirective implements AfterViewInit {
  constructor(private host: ElementRef<HTMLInputElement>) {}

  ngAfterViewInit() {
    if (!this.host.nativeElement.autofocus) return;

    this.host.nativeElement.focus();
  }
}
