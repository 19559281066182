export namespace RegExpList {
  export const allowedCharacters =
    /^[A-Za-z0-9\@\#\$\%\^\&\*\-\_\!\+\=\[\]\{\}]{1,}$/;

  export const capitalLetter = /[A-Z]{1,}/;

  export const lowercaseLetter = /[a-z]{1,}/;

  export const allowedSymbol = /[\@\#\$\%\^\&\*\-\_\!\+\=\[\]\{\}]{1,}/;

  export const numbers = /[0-9]{1,}/;
}
