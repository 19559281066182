<div class="main">
  <div class="form">
    <ng-container #formContainer></ng-container>
  </div>

  <div class="logo" [ngClass]="{'logo_raster': !logoText, 'logo_vector': logoText}">
    <img *ngIf="!logoText"
         decoding="async"
         fetchpriority="auto"
         loading="lazy"
         srcset="assets/images/logo@1x.png 1x, assets/images/logo@2x.png 2x, assets/images/logo@4x.png 4x"
         alt="logo">
    <div class="logo-wrapper" *ngIf="logoText">
      <span class="logo-text">{{logoText}}</span>
      <img decoding="async"
           fetchpriority="auto"
           loading="lazy"
           src="assets/images/leaf.svg"
           alt="logo">
    </div>
  </div>
</div>

<footer class="footer">
  <div class="powered-by">
    <div class="powered-by-text">
      {{ 'footer' | translate }}
    </div>
    <div class="powered-by-logo">
      <img ngSrc="assets/images/leafio.svg"
           width="95"
           height="26"
           alt="powered by leafio">
    </div>
  </div>
</footer>

<div *ngIf="forgotPassVisibility | async"
     class="remember-password"
     (click)="forgotPassVisibility.next(false)">
  <app-remember-password></app-remember-password>
</div>
